<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].display.label"
                        vid="display"
                        :label="beForm[rep].display.label"
                        v-model="form[rep].display"
                        placeholder="Inserisci un codice"
                        :rules="getRules('display')"
                        maxlength="3"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-textarea
                        vid="title"
                        :name="beForm[rep].title.label"
                        label="Descrizione"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].is_anticipated.label"
                        vid="is_anticipated"
                        label="Acconto"
                        v-model="form[rep].is_anticipated"
                        :options="beForm[rep].is_anticipated.options"
                        :rules="getRules('is_anticipated')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Flusso contabilità fiscale"
                header-tag="header"
                class="mt-2"
                v-if="canVerb('fattutto', 'config')"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        name="fattutto_enabled"
                        groupLabel=""
                        :label="`Causale attiva all'esportazione per contabilità fiscale`"
                        v-model="form.fattutto.enabled"
                      />
                    </div>
                    <!-- <div class="form-group col-md-3">
                      <base-input
                        name="fattutto_code"
                        vid="fattutto_code"
                        label="Codice Causale per flusso esportazione contabilità fiscale"
                        v-model="form.fattutto.code"
                        placeholder="Inserisci un codice"
                        textcase="upper"
                        :rules="getCustomRules('fattutto_code')"
                      /></div
                  > -->
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Codici Di Riferimento"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="status_broker"
                        :name="beForm[rep].status_broker.label"
                        label="Codice produttore"
                        v-model="form[rep].status_broker"
                        :options="beForm[rep].status_broker.options"
                        :rules="getRules('status_broker')"
                        stacked
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="status_insurer"
                        :name="beForm[rep].status_insurer.label"
                        label="Codice Compagnia"
                        v-model="form[rep].status_insurer"
                        :options="beForm[rep].status_insurer.options"
                        :rules="getRules('status_insurer')"
                        stacked
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="status_registry"
                        :name="beForm[rep].status_registry.label"
                        label="Anagrafica Cliente"
                        v-model="form[rep].status_registry"
                        :options="beForm[rep].status_registry.options"
                        :rules="getRules('status_registry')"
                        stacked
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                :header="config.header"
                header-tag="header"
                class="mt-1"
                v-for="(config, index) in treasuries_config"
                :key="`config${index}`"
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(treasury, index) in getFilteredTreasuries(
                        treasuries,
                        config.is_system,
                        config.is_abstract,
                        config.is_agency
                      )"
                      :key="index"
                    >
                      <base-radio
                        :vid="`${config.type}-${treasury.value}`"
                        :name="`${config.type}-${treasury.value}`"
                        :label="treasury.text"
                        v-model="treasury[`option`]"
                        :options="beForm[rep].value_type.options"
                        :slotLabel="true"
                        :rules="getRules('value_type')"
                        stacked
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import { mapGetters } from "vuex";
import { getFilteredTreasuries } from "@/utils/accounting";
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "various_accounting",
      id: this.$route.params.id,
      treasuries: [],
      RECUPERO_ACCONTO: "UC",
      treasuries_config: [
        {
          header: "Comparti di compagnia",
          type: "abstract",
          is_system: "Y",
          is_abstract: "Y",
        },
        {
          header: "FORME DI PAGAMENTO DI SISTEMA",
          type: "system",
          is_system: "Y",
          is_abstract: "N",
          is_agency: "N",
        },
        {
          header: "FORME DI PAGAMENTO NON DI SISTEMA ",
          type: "not_system",
          is_system: "N",
          is_abstract: "N",
          is_agency: "N",
        },
      ],
      form: {
        fattutto: {
          // code: "",
          enabled: false,
        },
        various_accounting: {
          title: null,
          display: null,
          is_anticipated: null,
          status_broker: null,
          status_insurer: null,
          status_registry: null,
          is_system: "N",
        },
        treasury_options: {},
      },
    };
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInput,
    BaseTextarea,
    BaseSelect,
    BaseRadio,
  },
  methods: {
    canVerb,
    // getCustomRules(field) {
    //   let rules = {};
    //   if (field === "fattutto_code") {
    //     if (this.form.fattutto.enabled) {
    //       rules["required"] = true;
    //     } else {
    //       delete rules["required"];
    //     }
    //   }
    //   return rules;
    // },
    saveFattutto(id) {
      const Repo = RepositoryFactory.get("fattutto");
      const payload = {
        configurable_id: id,
        configurable_type: "variousAccounting",
        // code: this.form.fattutto.code,
        ledger_type: 3, // no ledger_type
        // note: "",
        active: this.form.fattutto.enabled,
      };
      Repo.store_configuration(payload)
        .then(() => {
          this.isLoading = false;
          this.onSucess();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    onSubmit() {
      this.addTreasuriesOptionsToForm();
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          let id = response.data.data.id;
          let promises = [];
          if (Object.keys(this.form["treasury_options"]).length) {
            promises.push(this.addTreasuries(id));
            Promise.all(promises)
              .then(() => {
                this.saveFattutto(id);
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: `${errMsg}`,
                });
                this.isLoading = false;
              });
          } else {
            this.saveFattutto(id);
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    onSucess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Vario Creato`,
      });
      this.shortcut(
        "various_accountings.index",
        null,
        null,
        "filterVariousAccountings"
      );
    },
    addTreasuries(id) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_update(id, "treasury", {
        treasury: this.form["treasury_options"],
      });
    },
    addTreasuriesOptionsToForm() {
      for (let i = 0; i < this.treasuries.length; i++) {
        if (this.treasuries[i].option != 0) {
          this.form["treasury_options"][this.treasuries[i].value] = {
            value_type: this.treasuries[i].option,
          };
        }
      }
    },
    setDefaultRadioButton() {
      this.form[this.rep].status_insurer = "N";
      this.form[this.rep].status_registry = "N";
      this.form[this.rep].status_broker = "N";
      for (let i = 0; i <= this.treasuries.length; i++) {
        if (this.treasuries[i]) {
          Object.assign(this.treasuries[i], { option: 0 });
        }
      }
    },
    setTreasuries() {
      this.treasuries = this.getAllTreasuries().filter(
        (e) => e.code !== this.RECUPERO_ACCONTO
      );

      this.treasuries.forEach((e) => {
        if (
          e.sector?.is_system?.value === "N" &&
          e.text.indexOf(`(${e.sector?.title})`) < 0
        ) {
          e.text = `${e.text}
          ↪ collegata al comparto <strong>${
            e.sector?.code ? " " + e.sector.code + " - " : ""
          }${e.sector?.title ? " " + e.sector.title : ""}<strong>`;
        }
      });
    },
    getFilteredTreasuries,
    ...mapGetters("auth", {
      getAllTreasuries: "allTreasuries",
    }),
  },
  created() {
    this.isLoading = true;
    this.setTreasuries();
    this.fetchCreateForm(this.repository).then(() => {
      this.setDefaultRadioButton();
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
